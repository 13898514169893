import { Navigate, useNavigate } from "react-router-dom";
// import { authStore, userStore } from "../../stores";
// import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { authStore, userStore } from "../../store";

interface AuthorizedRouteProps {
    route: {
        requiredRoles: string[];
        resource: string;
        action: string;
    } | null;

    userRole: any;
    children: React.ReactNode;
}

const AuthorizedRoute: React.FC<AuthorizedRouteProps> = ({ route, userRole, children }) => {
    const navigate = useNavigate();
    const { accessToken, setAccessToken, setAuthorized } = authStore((state) => state);
    const { setUser } = userStore((state) => state);

    const [wipeData, setWipeData] = useState<boolean>(false);

    useEffect(() => {
        if (accessToken) {
            //     const decodedToken: { sub: string; username: string; exp: number; iat: number } =
            //         jwt_decode(accessToken ?? localStorage.getItem("auth-storage"));
            //     const currentTime = Math.floor(Date.now() / 1000);
            //     if (currentTime > decodedToken.exp) {
            //         localStorage.removeItem("auth-storage");
            //         setUser(null);
            //         setAccessToken(null);
            //         navigate("/");
            //     }
        } else {
            // localStorage.clear();
            // setAuthorized(false);
            // setAccessToken("");
            // navigate("/");
        }
    }, [accessToken, navigate]);

    // const hasRequiredRole = userRole?.roles?.some((role: any) => {
    //     return (
    //         route?.requiredRoles.includes(role.title) &&
    //         role.permissions?.some((permission: any) => {
    //             return permission.resource === route.resource && permission.action === route.action;
    //         })
    //     );
    // });

    // if (wipeData) {
    //     localStorage.clear();
    //     // setUser(null);
    //     // setAccessToken(null);

    //     return <Navigate to={"/"} />;
    // }

    return <>{children}</>;
};

export default AuthorizedRoute;
