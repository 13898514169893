import React, { useCallback } from "react";
import { cartReducer, State, initialState } from "./cart.reducer";
import { Item, getItem, inStock } from "./cart.utils";
import { useLocalStorage } from "../../utils/use-local-storage";
import { useAtom } from "jotai";
import { CART_KEY } from "../../utils/constants";
import { verifiedResponseAtom } from "../checkout";

interface CartProviderState extends State {
    addItemsToCart: (items: Item[]) => void;
    addItemToCart: (item: Item, quantity: number) => void;
    removeItemFromCart: (id: Item["id"]) => void;
    clearItemFromCart: (id: Item["id"]) => void;
    getItemFromCart: (id: Item["id"]) => any | undefined;
    isInCart: (id: Item["id"]) => boolean;
    isInStock: (id: Item["id"]) => boolean;
    resetCart: () => void;
    updateCartLanguage: (language: string) => void;
}
export const cartContext = React.createContext<CartProviderState | undefined>(undefined);

cartContext.displayName = "CartContext";

export const useCart = () => {
    const context = React.useContext(cartContext);
    if (context === undefined) {
        throw new Error(`useCart must be used within a CartProvider`);
    }
    return React.useMemo(() => context, [context]);
};

export const CartProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
    const [savedCart, saveCart] = useLocalStorage(CART_KEY, JSON.stringify(initialState));
    const [state, dispatch] = React.useReducer(
        cartReducer,
        savedCart ? JSON.parse(savedCart) : initialState
    );
    const [, emptyVerifiedResponse] = useAtom(verifiedResponseAtom);
    React.useEffect(() => {
        emptyVerifiedResponse(null);
    }, [emptyVerifiedResponse, state]);

    React.useEffect(() => {
        saveCart(JSON.stringify(state));
    }, [state, saveCart]);

    const addItemsToCart = (items: Item[]) => dispatch({ type: "ADD_ITEMS_WITH_QUANTITY", items });
    const addItemToCart = (item: Item, quantity: number) =>
        dispatch({ type: "ADD_ITEM_WITH_QUANTITY", item, quantity });
    const removeItemFromCart = (id: Item["id"]) =>
        dispatch({ type: "REMOVE_ITEM_OR_QUANTITY", id });
    const clearItemFromCart = (id: Item["id"]) => dispatch({ type: "REMOVE_ITEM", id });
    const isInCart = useCallback((id: Item["id"]) => !!getItem(state.items, id), [state.items]);
    const getItemFromCart = useCallback(
        (id: Item["id"]) => getItem(state.items, id),
        [state.items]
    );
    const isInStock = useCallback((id: Item["id"]) => inStock(state.items, id), [state.items]);
    const updateCartLanguage = (language: string) =>
        dispatch({ type: "UPDATE_CART_LANGUAGE", language });
    const resetCart = () => dispatch({ type: "RESET_CART" });
    const value = React.useMemo(
        () => ({
            ...state,
            addItemsToCart,
            addItemToCart,
            removeItemFromCart,
            clearItemFromCart,
            getItemFromCart,
            isInCart,
            isInStock,
            resetCart,
            updateCartLanguage,
        }),
        [getItemFromCart, isInCart, isInStock, state]
    );
    return <cartContext.Provider value={value} {...props} />;
};
