import BannerWithSearch from "./banner-with-search";

// const BannerWithSearch = import("./banner-with-search");
// import { useType } from "@/framework/type";
// import ErrorMessage from "../ui/error-message";
// import dynamic from "next/dynamic";
// const ErrorMessage = React.lazy(() => import("@/components/ui/error-message"));
// const BannerShort = dynamic(() => import("@/components/banners/banner-short"));
// const BannerWithoutSlider = dynamic(() => import("@/components/banners/banner-without-slider"));
// const BannerWithPagination = dynamic(() => import("@/components/banners/banner-with-pagination"));
// const MAP_BANNER_TO_GROUP: Record<string, any> = {
//     classic: BannerWithSearch,
//     // modern: BannerShort,
//     // minimal: BannerWithoutSlider,
//     standard: BannerWithSearch,
//     // compact: BannerWithPagination,
//     default: BannerWithSearch,
// };

const Banner = () => {
    // const { type, error } = useType(variables.type);
    // if (error) return <ErrorMessage message={error.message} />;

    // const Component =  BannerWithSearch ;//MAP_BANNER_TO_GROUP[layout];

    const bannerTypes = [
        {
            id: "",
            title: "Shop your designer dresses",
            description:
                "Ready to wear dresses tailored for you online. Hurry up while stock lasts.",
            image: {
                id: 907,
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/grocery.png",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/grocery-thumbnail.jpg",
            },
        },
    ];
    const slug = "clout";
    return <BannerWithSearch banners={bannerTypes} />;
};

export default Banner;
