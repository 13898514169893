import Banner from "../banners/banner";
import Categories from "../categories/categories";
import PromotionSliders from "../promotions/promotions";
import FilterBar from "./filter-bar";
import ProductGridHome from "../products/grids/home";

export default function ClassicLayout({ variables }: any) {
    return (
        <div>
            <Banner />

            <PromotionSliders />

            <FilterBar />

            <div className="flex border-t border-solid border-border-200 border-opacity-70">
                <Categories layout="classic" variables={variables.categories} />
                <ProductGridHome className="px-4 pb-8 lg:p-8" variables={variables.products} />
            </div>
        </div>
    );
}
