import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Product, ProductSearchGridShowItem } from "../../types";
import { PRODUCTS_PER_PAGE } from "../../service/client/variables";
import ErrorMessage from "../ui/error-message";
import NotFound from "../ui/not-found";
import Button from "../ui/button";
import rangeMap from "../../utils/range-map";
import ProductLoader from "../ui/loaders/product-loader";
import ProductCard, { ProductCard_V2 } from "./cards/card";
// import Button from '@/components/ui/button';
// import ProductLoader from '@/components/ui/loaders/product-loader';
// import NotFound from '@/components/ui/not-found';
// import rangeMap from '@/lib/range-map';
// import ProductCard from '@/components/products/cards/card';
// import ErrorMessage from '@/components/ui/error-message';
// import { useProducts } from '@/framework/product';
// import { PRODUCTS_PER_PAGE } from '@/framework/client/variables';
// import type { Product } from '@/types';

interface Props {
    limit?: number;
    sortedBy?: string;
    orderBy?: string;
    column?: "five" | "auto";
    shopId?: string;
    gridClassName?: string;
    products: Product[] | undefined;
    isLoading?: boolean;
    error?: any;
    loadMore?: any;
    isLoadingMore?: boolean;
    hasMore?: boolean;
    className?: string;
}

interface Props_V2 {
    limit?: number;
    sortedBy?: string;
    orderBy?: string;
    column?: "five" | "auto";
    shopId?: string;
    gridClassName?: string;
    products: ProductSearchGridShowItem[] | undefined;
    isLoading?: boolean;
    error?: any;
    loadMore?: any;
    isLoadingMore?: boolean;
    hasMore?: boolean;
    className?: string;
}

export function Grid({
    className,
    gridClassName,
    products,
    isLoading,
    error,
    loadMore,
    isLoadingMore,
    hasMore,
    limit = PRODUCTS_PER_PAGE,
    column = "auto",
}: Props) {
    const { t } = useTranslation("common");

    if (error) return <ErrorMessage message={error.message} />;

    if (!isLoading && !products?.length) {
        return (
            <div className="w-full min-h-full px-4 pt-6 pb-8 lg:p-8">
                <NotFound text="text-not-found" className="w-7/12 mx-auto" />
            </div>
        );
    }

    return (
        <div className={cn("w-full", className)}>
            <div
                className={cn(
                    {
                        "grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-3":
                            column === "auto",
                        "grid grid-cols-[repeat(auto-fill,minmax(260px,1fr))] gap-6 gap-y-10 lg:grid-cols-[repeat(auto-fill,minmax(200px,1fr))] xl:grid-cols-[repeat(auto-fill,minmax(220px,1fr))] xl:gap-8 xl:gap-y-11 2xl:grid-cols-5 3xl:grid-cols-[repeat(auto-fill,minmax(360px,1fr))]":
                            column === "five",
                    },
                    gridClassName
                )}
            >
                {isLoading && !products?.length
                    ? rangeMap(limit, (i) => <ProductLoader key={i} uniqueKey={`product-${i}`} />)
                    : products?.map((product) => (
                          <ProductCard product={product} key={product.id} />
                      ))}
            </div>
            {hasMore && (
                <div className="flex justify-center mt-8 lg:mt-12">
                    <Button
                        loading={isLoadingMore}
                        onClick={loadMore}
                        className="text-sm font-semibold h-11 md:text-base"
                    >
                        {t("text-load-more")}
                    </Button>
                </div>
            )}
        </div>
    );
}

export function Grid_V2({
    className,
    gridClassName,
    products,
    isLoading,
    error,
    loadMore,
    isLoadingMore,
    hasMore,
    limit = PRODUCTS_PER_PAGE,
    column = "auto",
}: Props_V2) {
    const { t } = useTranslation("common");

    if (error) return <ErrorMessage message={error.message} />;

    if (!isLoading && !products?.length) {
        return (
            <div className="w-full min-h-full px-4 pt-6 pb-8 lg:p-8">
                <NotFound text="text-not-found" className="w-7/12 mx-auto" />
            </div>
        );
    }

    return (
        <div className={cn("w-full", className)}>
            <div
                className={cn(
                    {
                        "grid grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-3":
                            column === "auto",
                        "grid grid-cols-[repeat(auto-fill,minmax(260px,1fr))] gap-6 gap-y-10 lg:grid-cols-[repeat(auto-fill,minmax(200px,1fr))] xl:grid-cols-[repeat(auto-fill,minmax(220px,1fr))] xl:gap-8 xl:gap-y-11 2xl:grid-cols-5 3xl:grid-cols-[repeat(auto-fill,minmax(360px,1fr))]":
                            column === "five",
                    },
                    gridClassName
                )}
            >
                {isLoading && !products?.length
                    ? rangeMap(limit, (i) => <ProductLoader key={i} uniqueKey={`product-${i}`} />)
                    : products?.map((product) => (
                          <ProductCard_V2 product={product} key={product.row} />
                      ))}
            </div>
            {hasMore && (
                <div className="flex justify-center mt-8 lg:mt-12">
                    <Button
                        loading={isLoadingMore}
                        onClick={loadMore}
                        className="text-sm font-semibold h-11 md:text-base"
                    >
                        {t("text-load-more")}
                    </Button>
                </div>
            )}
        </div>
    );
}

interface ProductsGridProps {
    className?: string;
    gridClassName?: string;
    variables?: any;
    column?: "five" | "auto";
}

const productData = {
    data: [
        {
            id: 111,
            name: "Mango Self Striped A Line Dress",
            slug: "mango-self-striped-a-line-dress",
            description:
                "Off-White self-striped knitted midi A-line dress, has a scoop neck, sleeveless, straight hem",
            type_id: 5,
            price: null,
            shop_id: 2,
            sale_price: null,
            language: "en",
            min_price: 300,
            max_price: 350,
            sku: null,
            quantity: 1500,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "variable",
            unit: "1 Stk",
            height: null,
            width: null,
            length: null,
            image: {
                id: "112",
                original: "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/112/mango.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/112/conversions/mango-thumbnail.jpg",
            },
            video: null,
            gallery: [],
            deleted_at: null,
            created_at: "2021-03-09T17:56:41.000Z",
            updated_at: "2022-07-04T15:32:26.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 5,
            total_reviews: 1,
            rating_count: [
                {
                    rating: 5,
                    total: 1,
                    positive_feedbacks_count: 0,
                    negative_feedbacks_count: 0,
                    my_feedback: null,
                    abusive_reports_count: 0,
                },
            ],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 68,
                    name: "Women Dress",
                    slug: "women-dress",
                    language: "en",
                    icon: "WomenDress",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T06:25:14.000000Z",
                    updated_at: "2021-03-09T06:25:14.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 111,
                        category_id: 68,
                    },
                },
                {
                    id: 71,
                    name: "Single Color",
                    slug: "single-color",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 68,
                    type_id: 5,
                    created_at: "2021-03-09T06:27:12.000000Z",
                    updated_at: "2021-03-09T06:27:12.000000Z",
                    deleted_at: null,
                    parent_id: 68,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 111,
                        category_id: 71,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [
                {
                    id: 8,
                    slug: "red",
                    attribute_id: 3,
                    value: "Red",
                    language: "en",
                    meta: "#ce1f6a",
                    created_at: "2021-05-09T16:10:56.000000Z",
                    updated_at: "2021-05-09T18:53:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 111,
                        attribute_value_id: 8,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 9,
                    slug: "blue",
                    attribute_id: 3,
                    value: "Blue",
                    language: "en",
                    meta: "#344fa1",
                    created_at: "2021-05-09T16:11:20.000000Z",
                    updated_at: "2021-05-09T18:52:35.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 111,
                        attribute_value_id: 9,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 11,
                    slug: "s",
                    attribute_id: 4,
                    value: "S",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:43.000000Z",
                    updated_at: "2021-05-09T16:11:43.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 111,
                        attribute_value_id: 11,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 12,
                    slug: "m",
                    attribute_id: 4,
                    value: "M",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:50.000000Z",
                    updated_at: "2021-05-09T16:11:50.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 111,
                        attribute_value_id: 12,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 13,
                    slug: "l",
                    attribute_id: 4,
                    value: "L",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T17:45:30.000000Z",
                    updated_at: "2021-05-09T17:45:30.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 111,
                        attribute_value_id: 13,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
            ],
            metas: [],
            manufacturer: null,
            variation_options: [
                {
                    id: 255,
                    title: "Red/S",
                    image: null,
                    price: "80",
                    sale_price: "70",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200120",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 111,
                    created_at: "2021-05-10T09:12:37.000000Z",
                    updated_at: "2021-05-10T09:12:37.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 256,
                    title: "Red/M",
                    image: null,
                    price: "80",
                    sale_price: "70",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200121",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 111,
                    created_at: "2021-05-10T09:12:37.000000Z",
                    updated_at: "2021-05-10T09:12:37.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 257,
                    title: "Red/L",
                    image: null,
                    price: "80",
                    sale_price: "70",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200122",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "L",
                        },
                    ],
                    product_id: 111,
                    created_at: "2021-05-10T09:12:37.000000Z",
                    updated_at: "2021-05-10T09:12:37.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 258,
                    title: "Blue/S",
                    image: null,
                    price: "80",
                    sale_price: "70",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200123",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 111,
                    created_at: "2021-05-10T09:12:37.000000Z",
                    updated_at: "2021-05-10T09:12:37.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 259,
                    title: "Blue/M",
                    image: null,
                    price: "81",
                    sale_price: "70",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200124",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 111,
                    created_at: "2021-05-10T09:12:37.000000Z",
                    updated_at: "2021-05-10T09:12:37.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 260,
                    title: "Blue/L",
                    image: null,
                    price: "80",
                    sale_price: "70",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200125",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "L",
                        },
                    ],
                    product_id: 111,
                    created_at: "2021-05-10T09:12:37.000000Z",
                    updated_at: "2021-05-10T09:12:37.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
            ],
            tags: [],
            author: null,
        },
        {
            id: 113,
            name: "Forever 21 Solid Bodycon Midi Dress",
            slug: "forever-21-solid-bodycon-midi-dress",
            description:
                "Grey solid woven bodycon dress, has a round neck, sleeveless, straight hem",
            type_id: 5,
            price: null,
            shop_id: 2,
            sale_price: null,
            language: "en",
            min_price: 300,
            max_price: 350,
            sku: null,
            quantity: 1500,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "variable",
            unit: "1 Stk",
            height: null,
            width: null,
            length: null,
            image: {
                id: "115",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/115/FOREVER_21.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/115/conversions/FOREVER_21-thumbnail.jpg",
            },
            video: null,
            gallery: [
                {
                    id: "672",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/671/Striped-Dress.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/671/conversions/Striped-Dress-thumbnail.jpg",
                },
                {
                    id: "768",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/767/FOREVER_21.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/767/conversions/FOREVER_21-thumbnail.jpg",
                },
            ],
            deleted_at: null,
            created_at: "2021-03-09T18:01:23.000Z",
            updated_at: "2022-07-04T15:32:26.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 3,
            total_reviews: 1,
            rating_count: [
                {
                    rating: 3,
                    total: 1,
                    positive_feedbacks_count: 0,
                    negative_feedbacks_count: 0,
                    my_feedback: null,
                    abusive_reports_count: 0,
                },
            ],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 68,
                    name: "Women Dress",
                    slug: "women-dress",
                    language: "en",
                    icon: "WomenDress",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T06:25:14.000000Z",
                    updated_at: "2021-03-09T06:25:14.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 113,
                        category_id: 68,
                    },
                },
                {
                    id: 70,
                    name: "Floral",
                    slug: "floral",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 68,
                    type_id: 5,
                    created_at: "2021-03-09T06:26:52.000000Z",
                    updated_at: "2021-03-09T06:26:52.000000Z",
                    deleted_at: null,
                    parent_id: 68,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 113,
                        category_id: 70,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [
                {
                    id: 9,
                    slug: "blue",
                    attribute_id: 3,
                    value: "Blue",
                    language: "en",
                    meta: "#344fa1",
                    created_at: "2021-05-09T16:11:20.000000Z",
                    updated_at: "2021-05-09T18:52:35.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 113,
                        attribute_value_id: 9,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 10,
                    slug: "white",
                    attribute_id: 3,
                    value: "White",
                    language: "en",
                    meta: "#e1e5ea",
                    created_at: "2021-05-09T16:11:35.000000Z",
                    updated_at: "2021-05-09T18:58:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 113,
                        attribute_value_id: 10,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 12,
                    slug: "m",
                    attribute_id: 4,
                    value: "M",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:50.000000Z",
                    updated_at: "2021-05-09T16:11:50.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 113,
                        attribute_value_id: 12,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 13,
                    slug: "l",
                    attribute_id: 4,
                    value: "L",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T17:45:30.000000Z",
                    updated_at: "2021-05-09T17:45:30.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 113,
                        attribute_value_id: 13,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 14,
                    slug: "xl",
                    attribute_id: 4,
                    value: "XL",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T17:45:39.000000Z",
                    updated_at: "2021-05-09T17:45:39.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 113,
                        attribute_value_id: 14,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
            ],
            metas: [],
            manufacturer: null,
            variation_options: [
                {
                    id: 261,
                    title: "Blue/M",
                    image: null,
                    price: "120",
                    sale_price: "100",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200130",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 113,
                    created_at: "2021-05-10T09:14:04.000000Z",
                    updated_at: "2021-05-10T09:14:04.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 262,
                    title: "Blue/L",
                    image: null,
                    price: "120",
                    sale_price: "100",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200131",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "L",
                        },
                    ],
                    product_id: 113,
                    created_at: "2021-05-10T09:14:04.000000Z",
                    updated_at: "2021-05-10T09:14:04.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 263,
                    title: "Blue/XL",
                    image: null,
                    price: "120",
                    sale_price: "100",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200132",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "XL",
                        },
                    ],
                    product_id: 113,
                    created_at: "2021-05-10T09:14:04.000000Z",
                    updated_at: "2021-05-10T09:14:04.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 264,
                    title: "White/M",
                    image: null,
                    price: "120",
                    sale_price: null,
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200135",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 113,
                    created_at: "2021-05-10T09:14:04.000000Z",
                    updated_at: "2021-05-10T09:14:04.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 265,
                    title: "White/L",
                    image: null,
                    price: "120",
                    sale_price: "100",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200136",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "L",
                        },
                    ],
                    product_id: 113,
                    created_at: "2021-05-10T09:14:04.000000Z",
                    updated_at: "2021-05-10T09:14:04.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 266,
                    title: "White/XL",
                    image: null,
                    price: "120",
                    sale_price: "100",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200138",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "XL",
                        },
                    ],
                    product_id: 113,
                    created_at: "2021-05-10T09:14:04.000000Z",
                    updated_at: "2021-05-10T09:14:04.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
            ],
            tags: [],
            author: null,
        },
        {
            id: 114,
            name: "Dorothy Perkins Self Striped A Line Dress",
            slug: "dorothy-perkins-self-striped-a-line-dress",
            description:
                "Rust red self-striped knitted A-line dress, has a V-neck with button detailing, three-quarter sleeves, flared hem",
            type_id: 5,
            price: null,
            shop_id: 2,
            sale_price: null,
            language: "en",
            min_price: 300,
            max_price: 350,
            sku: null,
            quantity: 1500,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "variable",
            unit: "1 Stk",
            height: null,
            width: null,
            length: null,
            image: {
                id: "116",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/116/DOROTHY_PERKINS.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/116/conversions/DOROTHY_PERKINS-thumbnail.jpg",
            },
            video: null,
            gallery: [
                {
                    id: "674",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/673/Striped-Dress-2.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/673/conversions/Striped-Dress-2-thumbnail.jpg",
                },
                {
                    id: "675",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/674/Striped-Dress.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/674/conversions/Striped-Dress-thumbnail.jpg",
                },
                {
                    id: "769",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/768/DOROTHY_PERKINS.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/768/conversions/DOROTHY_PERKINS-thumbnail.jpg",
                },
            ],
            deleted_at: null,
            created_at: "2021-03-09T18:02:33.000Z",
            updated_at: "2022-07-04T15:32:26.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 0,
            total_reviews: 0,
            rating_count: [],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 68,
                    name: "Women Dress",
                    slug: "women-dress",
                    language: "en",
                    icon: "WomenDress",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T06:25:14.000000Z",
                    updated_at: "2021-03-09T06:25:14.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 114,
                        category_id: 68,
                    },
                },
                {
                    id: 71,
                    name: "Single Color",
                    slug: "single-color",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 68,
                    type_id: 5,
                    created_at: "2021-03-09T06:27:12.000000Z",
                    updated_at: "2021-03-09T06:27:12.000000Z",
                    deleted_at: null,
                    parent_id: 68,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 114,
                        category_id: 71,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [
                {
                    id: 8,
                    slug: "red",
                    attribute_id: 3,
                    value: "Red",
                    language: "en",
                    meta: "#ce1f6a",
                    created_at: "2021-05-09T16:10:56.000000Z",
                    updated_at: "2021-05-09T18:53:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 114,
                        attribute_value_id: 8,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 9,
                    slug: "blue",
                    attribute_id: 3,
                    value: "Blue",
                    language: "en",
                    meta: "#344fa1",
                    created_at: "2021-05-09T16:11:20.000000Z",
                    updated_at: "2021-05-09T18:52:35.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 114,
                        attribute_value_id: 9,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 10,
                    slug: "white",
                    attribute_id: 3,
                    value: "White",
                    language: "en",
                    meta: "#e1e5ea",
                    created_at: "2021-05-09T16:11:35.000000Z",
                    updated_at: "2021-05-09T18:58:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 114,
                        attribute_value_id: 10,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 11,
                    slug: "s",
                    attribute_id: 4,
                    value: "S",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:43.000000Z",
                    updated_at: "2021-05-09T16:11:43.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 114,
                        attribute_value_id: 11,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 12,
                    slug: "m",
                    attribute_id: 4,
                    value: "M",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:50.000000Z",
                    updated_at: "2021-05-09T16:11:50.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 114,
                        attribute_value_id: 12,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
            ],
            metas: [],
            manufacturer: null,
            variation_options: [
                {
                    id: 267,
                    title: "Red/S",
                    image: null,
                    price: "140",
                    sale_price: "130",
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200140",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 114,
                    created_at: "2021-05-10T09:15:47.000000Z",
                    updated_at: "2021-05-10T09:15:47.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 268,
                    title: "Red/M",
                    image: null,
                    price: "140",
                    sale_price: "130",
                    language: "en",
                    quantity: 100,
                    is_disable: 0,
                    sku: "200141",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 114,
                    created_at: "2021-05-10T09:15:47.000000Z",
                    updated_at: "2021-05-10T09:15:47.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 269,
                    title: "Blue/S",
                    image: null,
                    price: "140",
                    sale_price: "130",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200142",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 114,
                    created_at: "2021-05-10T09:15:47.000000Z",
                    updated_at: "2021-05-10T09:15:47.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 270,
                    title: "Blue/M",
                    image: null,
                    price: "140",
                    sale_price: "130",
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200143",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 114,
                    created_at: "2021-05-10T09:15:47.000000Z",
                    updated_at: "2021-05-10T09:15:47.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 271,
                    title: "White/S",
                    image: null,
                    price: "140",
                    sale_price: "130",
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200144",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 114,
                    created_at: "2021-05-10T09:15:47.000000Z",
                    updated_at: "2021-05-10T09:15:47.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 272,
                    title: "White/M",
                    image: null,
                    price: "140",
                    sale_price: "130",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200146",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 114,
                    created_at: "2021-05-10T09:15:47.000000Z",
                    updated_at: "2021-05-10T09:15:47.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
            ],
            tags: [],
            author: null,
        },
        {
            id: 116,
            name: "Fold Over Collar Plain Blazers",
            slug: "fold-over-collar-plain-blazers",
            description:
                "Black bandhgala, has a mandarin collar, a full button placket, long sleeves, three pockets, double vented back hem, and has an attached lining",
            type_id: 5,
            price: null,
            shop_id: 2,
            sale_price: null,
            language: "en",
            min_price: 300,
            max_price: 350,
            sku: null,
            quantity: 1500,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "variable",
            unit: "1 Stk",
            height: null,
            width: null,
            length: null,
            image: {
                id: "117",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/117/Fold_over.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/117/conversions/Fold_over-thumbnail.jpg",
            },
            video: null,
            gallery: [
                {
                    id: "692",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/691/Plain-Blazers-4.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/691/conversions/Plain-Blazers-4-thumbnail.jpg",
                },
                {
                    id: "693",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/692/Plain-Blazers-5.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/692/conversions/Plain-Blazers-5-thumbnail.jpg",
                },
                {
                    id: "774",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/773/Fold_over.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/773/conversions/Fold_over-thumbnail.jpg",
                },
            ],
            deleted_at: null,
            created_at: "2021-03-09T18:04:53.000Z",
            updated_at: "2022-07-04T15:32:26.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 3,
            total_reviews: 1,
            rating_count: [
                {
                    rating: 3,
                    total: 1,
                    positive_feedbacks_count: 0,
                    negative_feedbacks_count: 0,
                    my_feedback: null,
                    abusive_reports_count: 0,
                },
            ],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 72,
                    name: "Outer Wear",
                    slug: "outer-wear",
                    language: "en",
                    icon: "OuterWear",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T08:55:55.000000Z",
                    updated_at: "2021-03-09T08:55:55.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 116,
                        category_id: 72,
                    },
                },
                {
                    id: 75,
                    name: "Blazer",
                    slug: "blazer",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 72,
                    type_id: 5,
                    created_at: "2021-03-09T08:57:20.000000Z",
                    updated_at: "2021-03-09T08:57:20.000000Z",
                    deleted_at: null,
                    parent_id: 72,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 116,
                        category_id: 75,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [
                {
                    id: 9,
                    slug: "blue",
                    attribute_id: 3,
                    value: "Blue",
                    language: "en",
                    meta: "#344fa1",
                    created_at: "2021-05-09T16:11:20.000000Z",
                    updated_at: "2021-05-09T18:52:35.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 116,
                        attribute_value_id: 9,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 10,
                    slug: "white",
                    attribute_id: 3,
                    value: "White",
                    language: "en",
                    meta: "#e1e5ea",
                    created_at: "2021-05-09T16:11:35.000000Z",
                    updated_at: "2021-05-09T18:58:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 116,
                        attribute_value_id: 10,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 12,
                    slug: "m",
                    attribute_id: 4,
                    value: "M",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:50.000000Z",
                    updated_at: "2021-05-09T16:11:50.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 116,
                        attribute_value_id: 12,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 14,
                    slug: "xl",
                    attribute_id: 4,
                    value: "XL",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T17:45:39.000000Z",
                    updated_at: "2021-05-09T17:45:39.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 116,
                        attribute_value_id: 14,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
            ],
            metas: [],
            manufacturer: null,
            variation_options: [
                {
                    id: 273,
                    title: "Blue/M",
                    image: null,
                    price: "199",
                    sale_price: null,
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200160",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 116,
                    created_at: "2021-05-10T09:16:55.000000Z",
                    updated_at: "2021-05-10T09:16:55.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 274,
                    title: "Blue/XL",
                    image: null,
                    price: "200",
                    sale_price: null,
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200161",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "XL",
                        },
                    ],
                    product_id: 116,
                    created_at: "2021-05-10T09:16:55.000000Z",
                    updated_at: "2021-05-10T09:16:55.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 275,
                    title: "White/M",
                    image: null,
                    price: "200",
                    sale_price: null,
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200162",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 116,
                    created_at: "2021-05-10T09:16:55.000000Z",
                    updated_at: "2021-05-10T09:16:55.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 276,
                    title: "White/XL",
                    image: null,
                    price: "200",
                    sale_price: null,
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200164",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "XL",
                        },
                    ],
                    product_id: 116,
                    created_at: "2021-05-10T09:16:55.000000Z",
                    updated_at: "2021-05-10T09:16:55.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
            ],
            tags: [],
            author: null,
        },
    ],
    total: 63,
    current_page: 1,
    count: 30,
    last_page: 3,
    firstItem: 0,
    lastItem: 29,
    per_page: "30",
    first_page_url:
        "http://localhost:5000/api/products?search=type.slug:clothing;status:publish&limit=30&page=1",
    last_page_url:
        "http://localhost:5000/api/products?search=type.slug:clothing;status:publish&limit=30&page=3",
    next_page_url:
        "http://localhost:5000/api/products?search=type.slug:clothing;status:publish&limit=30&page=2",
    prev_page_url:
        "http://localhost:5000/api/products?search=type.slug:clothing;status:publish&limit=30&page=1",
};
export default function ProductsGrid({
    className,
    gridClassName,
    variables,
    column = "auto",
}: ProductsGridProps) {
    // const { products, loadMore, isLoadingMore, isLoading, hasMore, error } = useProducts(variables);

    const productsItem: any = productData.data ?? [];
    return (
        <Grid
            products={productsItem}
            loadMore={() => {}}
            isLoading={false}
            isLoadingMore={false}
            hasMore={false}
            error={undefined}
            className={className}
            gridClassName={gridClassName}
            column={column}
        />
    );
}
