import type {
  Attachment,
  Author,
  AuthorPaginator,
  AuthorQueryOptions,
  AuthResponse,
  CategoryPaginator,
  CategoryQueryOptions,
  ChangePasswordUserInput,
  CheckoutVerificationInput,
  CouponPaginator,
  CouponQueryOptions,
  CreateAbuseReportInput,
  CreateContactUsInput,
  CreateFeedbackInput,
  CreateOrderInput,
  CreateQuestionInput,
  CreateRefundInput,
  CreateReviewInput,
  DownloadableFilePaginator,
  Feedback,
  ForgotPasswordUserInput,
  LoginUserInput,
  Manufacturer,
  ManufacturerPaginator,
  ManufacturerQueryOptions,
  MyQuestionQueryOptions,
  MyReportsQueryOptions,
  Order,
  OrderPaginator,
  OrderQueryOptions,
  OrderStatusPaginator,
  OtpLoginInputType,
  OTPResponse,
  PasswordChangeResponse,
  PopularProductQueryOptions,
  Product,
  ProductPaginator,
  ProductQueryOptions,
  QueryOptions,
  QuestionPaginator,
  QuestionQueryOptions,
  Refund,
  RefundPaginator,
  RegisterUserInput,
  ResetPasswordUserInput,
  Review,
  ReviewPaginator,
  ReviewQueryOptions,
  ReviewResponse,
  SendOtpCodeInputType,
  Settings,
  Shop,
  ShopPaginator,
  ShopQueryOptions,
  SocialLoginInputType,
  TagPaginator,
  TagQueryOptions,
  Type,
  TypeQueryOptions,
  UpdateReviewInput,
  UpdateUserInput,
  User,
  VerifiedCheckoutData,
  VerifyCouponInputType,
  VerifyCouponResponse,
  VerifyForgotPasswordUserInput,
  VerifyOtpInputType,
  Wishlist,
  WishlistPaginator,
  WishlistQueryOptions,
  GetParams,
  SettingsQueryOptions,
  CreateOrderPaymentInput,
  SetupIntentInfo,
  PaymentIntentCollection,
  Card,
  BestSellingProductQueryOptions,
  UpdateEmailUserInput,
  EmailChangeResponse,
  VerificationEmailUserInput,
  StoreNoticeQueryOptions,
  StoreNoticePaginator,
  StoreNotice,
  //@ts-ignore
  ShopMapLocation,
  UserMEInput,
  UserShowResponse,
  VerifyEmailInput,
  VerifyEmailResponse,
  ResetPasswordResponse,
  ProductCategoryShowNameReq,
  ProductCategoryShowNameResponse,
  ProductSearchGridShowResponse,
  ProductSearchGridShowReq,
  MarketPlaceShowReq,
  MarketPlaceShowResponse,
  UserMarketPlaceUpdateReq,
  UserMarketPlaceUpdateResponse,
  UserMarketPlaceStatusReq,
  UserMarketPlaceStatusResponse,
} from '../../types';
import { API_ENDPOINTS } from './api-endpoints';
import { HttpClient } from './http-client';

//@ts-ignore
import { OTPVerifyResponse } from '@/types';

class Client {
  users = {
    // me: () => HttpClient.get<User>(API_ENDPOINTS.USERS_ME),
    me_v2: (input: UserMEInput) =>
      HttpClient.post<UserShowResponse>(API_ENDPOINTS.USERS_ME, input),
    verifyEmail: (input: VerifyEmailInput) =>
      HttpClient.patch<VerifyEmailResponse>(
        API_ENDPOINTS.VERIFY_OTP_CODE,
        input
      ),
    update: (user: UpdateUserInput) =>
      HttpClient.put<User>(`${API_ENDPOINTS.USERS}/${user.id}`, user),
    login: (input: LoginUserInput) =>
      HttpClient.post<any>(API_ENDPOINTS.USERS_LOGIN, input),
    socialLogin: (input: SocialLoginInputType) =>
      HttpClient.post<AuthResponse>(API_ENDPOINTS.SOCIAL_LOGIN, input),
    sendOtpCode: (input: SendOtpCodeInputType) =>
      HttpClient.post<OTPResponse>(API_ENDPOINTS.SEND_OTP_CODE, input),
    verifyOtpCode: (input: VerifyOtpInputType) =>
      HttpClient.post<OTPVerifyResponse>(API_ENDPOINTS.VERIFY_OTP_CODE, input),
    OtpLogin: (input: OtpLoginInputType) =>
      HttpClient.post<AuthResponse>(API_ENDPOINTS.OTP_LOGIN, input),
    register: (input: RegisterUserInput) =>
      HttpClient.post<AuthResponse>(API_ENDPOINTS.USERS_REGISTER, input),
    forgotPassword: (input: ForgotPasswordUserInput) =>
      HttpClient.post<ResetPasswordResponse>(
        API_ENDPOINTS.USERS_FORGOT_PASSWORD,
        input
      ),
    userMarketPlaceUpdate: (input: UserMarketPlaceUpdateReq) =>
      HttpClient.post<UserMarketPlaceUpdateResponse>(
        API_ENDPOINTS.USER_MARKET_PLACE_STATUS_UPDATE,
        input
      ),
    userMarketPlaceStatus: (input: UserMarketPlaceStatusReq) =>
      HttpClient.post<UserMarketPlaceStatusResponse>(
        API_ENDPOINTS.USER_MARKET_PLACE_STATUS_STATUS,
        input
      ),
    verifyForgotPasswordToken: (input: VerifyForgotPasswordUserInput) =>
      HttpClient.post<PasswordChangeResponse>(
        API_ENDPOINTS.USERS_VERIFY_FORGOT_PASSWORD_TOKEN,
        input
      ),
    resetPassword: (input: ResetPasswordUserInput) =>
      HttpClient.patch<ResetPasswordResponse>(
        API_ENDPOINTS.USERS_RESET_PASSWORD,
        input
      ),
    changePassword: (input: ChangePasswordUserInput) =>
      HttpClient.post<PasswordChangeResponse>(
        API_ENDPOINTS.USERS_CHANGE_PASSWORD,
        input
      ),
    updateEmail: (input: UpdateEmailUserInput) =>
      HttpClient.post<EmailChangeResponse>(
        API_ENDPOINTS.USERS_UPDATE_EMAIL,
        input
      ),
    logout: () => HttpClient.post<boolean>(API_ENDPOINTS.USERS_LOGOUT, {}),
    deleteAddress: ({ id }: { id: string }) =>
      HttpClient.delete<boolean>(`${API_ENDPOINTS.USERS_ADDRESS}/${id}`),
    subscribe: (input: { email: string }) =>
      HttpClient.post<any>(API_ENDPOINTS.USERS_SUBSCRIBE_TO_NEWSLETTER, input),
    resendVerificationEmail: () => {
      return HttpClient.post<VerificationEmailUserInput>(
        API_ENDPOINTS.SEND_VERIFICATION_EMAIL,
        {}
      );
    }

  };

  customer = {
    productCategoryShowName: (input: ProductCategoryShowNameReq) =>
      HttpClient.post<ProductCategoryShowNameResponse>(API_ENDPOINTS.CUSTOMER_PRODUCT_CATEGORY_NAME_SHOW, input),
    productSearchGridShow: (input: ProductSearchGridShowReq) =>
      HttpClient.post<ProductSearchGridShowResponse>(`${API_ENDPOINTS.CUSTOMER_PRODUCT_SEARCH_GRID_SHOW}?page=${input.page}&items_per_page=${input.size}`, {
        marketplaceId: input.marketplaceId,
        level: input.level,
        category: input.category
      }),
    marketPlaceShow: (input: MarketPlaceShowReq) =>
      HttpClient.post<MarketPlaceShowResponse>(`${API_ENDPOINTS.CUSTOMER_MARKET_PLACE_SHOW}`, input),
    contactUs: (input: CreateContactUsInput) =>
      HttpClient.post<any>(API_ENDPOINTS.USERS_CONTACT_US, input),
  };

}

export default new Client();
