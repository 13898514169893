// import { TYPES_PER_PAGE } from '@/framework/client/variables';
// import { useTypes } from '@/framework/type';

import { TYPES_PER_PAGE } from "../../service/client/variables";

// import { useRouter } from 'next/router';

const typeData = [
    {
        id: 5,
        name: "Clothing",
        settings: {
            isHome: false,
            layoutType: "classic",
            productCard: "xenon",
        },
        slug: "clothing",
        language: "en",
        icon: "DressIcon",
        promotional_sliders: [
            {
                id: 902,
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
            },
            {
                id: 903,
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
            },
            {
                id: 904,
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
            },
            {
                id: 905,
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
            },
            {
                id: 906,
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
            },
        ],
        created_at: "2021-03-08T07:19:38.000Z",
        updated_at: "2021-08-18T18:34:07.000Z",
        translated_languages: ["en"],
        banners: [
            {
                id: 16,
                type_id: 5,
                title: "Shop your designer dresses",
                description:
                    "Ready to wear dresses tailored for you online. Hurry up while stock lasts.",
                image: {
                    id: 911,
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/908/cloths.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/908/conversions/cloths-thumbnail.jpg",
                },
                created_at: "2021-07-17T13:24:28.000000Z",
                updated_at: "2021-07-17T13:24:28.000000Z",
            },
        ],
    },
];

const useLayout = () => {
    // const data = useTypes({
    //     limit: TYPES_PER_PAGE,
    // });
    // const router = useRouter();
    // const regex = /^\/$|^\/\?(.*)/;
    // if (regex.test(router?.asPath)) {
    //     const homePage = typeData?.find((type) => type?.settings?.isHome) ?? typeData?.[0];
    //     return {
    //         layout: homePage?.settings?.layoutType ?? "default",
    //         page: homePage,
    //     };
    // }
    const page = typeData?.find((type) => {
        const currentPath = window.location.pathname;

        return currentPath.includes(type.slug);

        // router.asPath.includes(type.slug)
    });
    return {
        layout: page?.settings?.layoutType ?? "default",
        page,
    };
};

export default useLayout;
