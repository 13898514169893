import React, { useEffect, useRef } from "react";
import cn from "classnames";
import { Swiper, SwiperSlide, Navigation } from "../ui/slider";
import { useHeaderSearch } from "../../layouts/header-search-atom";
import { useIntersection } from "react-use";
import { Banner } from "../../types";
import Search from "../ui/search/search";

interface BannerProps {
    banners: Banner[] | undefined;
    layout?: string;
}

const BannerWithSearch: React.FC<BannerProps> = ({ banners, layout }) => {
    const { showHeaderSearch, hideHeaderSearch } = useHeaderSearch();

    const intersectionRef = useRef(null);

    const intersection = useIntersection(intersectionRef, {
        root: null,
        rootMargin: "0px",
        threshold: 1,
    });

    useEffect(() => {
        if (intersection && intersection.isIntersecting) {
            hideHeaderSearch();
            return;
        }
        if (intersection && !intersection.isIntersecting) {
            showHeaderSearch();
        }
    }, [intersection]);

    const imgUrl = "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/908/cloths.png";

    return (
        <div
            className={cn("textClass relative hidden lg:block", {
                "!block": layout === "minimal",
            })}
        >
            <div className="-z-1 overflow-hidden">
                <div className="relative">
                    <Swiper
                        id="banner"
                        // loop={true}
                        modules={[Navigation]}
                        resizeObserver={true}
                        allowTouchMove={false}
                        slidesPerView={1}
                    >
                        {banners?.map((banner, idx) => (
                            <SwiperSlide key={idx}>
                                <div
                                    className={cn("relative h-screen w-full", {
                                        "max-h-140": layout === "standard",
                                        "max-h-[320px] md:max-h-[680px]": layout === "minimal",
                                    })}
                                >
                                    <img
                                        // src={ProductPlaceholder}
                                        // className="h-full min-h-140 w-full object-cover"
                                        src={imgUrl}
                                        alt={banner.title ?? ""}
                                        // fill
                                        sizes="(max-width: 768px) 100vw"
                                    />
                                    <div
                                        className={cn(
                                            "absolute inset-0 mt-8 flex w-full flex-col items-center justify-center p-5 text-center md:px-20 lg:space-y-10",
                                            {
                                                "space-y-5 md:!space-y-8": layout === "minimal",
                                            }
                                        )}
                                    >
                                        <h1
                                            className={cn(
                                                "text-2xl font-bold tracking-tight text-heading lg:text-4xl xl:text-5xl",
                                                {
                                                    "!text-accent": layout === "minimal",
                                                }
                                            )}
                                        >
                                            {banner?.title}
                                        </h1>
                                        <p className="text-sm text-heading lg:text-base xl:text-lg">
                                            {banner?.description}
                                        </p>
                                        <div className="w-full max-w-3xl" ref={intersectionRef}>
                                            <Search label="search-box" />
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default BannerWithSearch;
