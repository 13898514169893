import React from "react";
import useLayout from "../../utils/hooks/use-layout";
import Header from "../header";
import HeaderMinimal from "./header-minimal";
import Footer from "./footer";

const MobileNavigation = React.lazy(() => import("./mobile-navigation"));

export default function SiteLayout({ children }: React.PropsWithChildren<{}>) {
    const { layout } = useLayout();
    // const router = useRouter();
    return (
        <div className="flex min-h-screen flex-col bg-gray-100 transition-colors duration-150">
            {/* {router.query.slug && <NoticeHighlightedBar />} */}

            {["minimal", "compact"].includes(layout) ? (
                <HeaderMinimal layout={layout} />
            ) : (
                <Header layout={layout} />
            )}
            {children}
            {["compact"].includes(layout) && <Footer />}
            <MobileNavigation />
        </div>
    );
}
export const getLayout = (page: React.ReactElement) => <SiteLayout>{page}</SiteLayout>;
