import { useTranslation } from "react-i18next";
import { Link, Element } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { termsAndServices } from "../../service/static/terms";

const TermsView = () => {
    const navigate = useNavigate();
    const { t } = useTranslation("terms");

    const { title, content } = termsAndServices;

    // const { mutate: logout, isLoading: isLogoutLoader } = useLogout();
    // useUser();

    // if (emailVerified) {
    //     router.push("/profile");
    // }
    // const handleLogout = () => {
    //     logout();
    //     router.push(Routes.home);
    // };

    function makeTitleToDOMId(title: string) {
        return title.toLowerCase().split(" ").join("_");
    }

    return (
        <section
            className="py-8 lg:py-10 xl:py-14  h-screen bg-white"
            style={{
                marginTop: 16,
            }}
        >
            <header className="flex w-full justify-center bg-slate-200 py-20 md:min-h-[250px] lg:min-h-[288px]">
                <h1 className="text-xl font-bold md:text-2xl xl:text-3xl">
                    {t('common:nav-menu-terms')}
                </h1>
            </header>
            {/* End of page header */}
            <div className='mx-auto w-full max-w-screen-lg px-4 py-10'>

            <div className="flex flex-col md:flex-row gap-10">
                <nav className="mb-8 md:mb-0 md:w-72 xl:w-3/12">
                    <ol className="sticky z-10 md:top-16 lg:top-22">
                        {content?.map((item) => (
                            <li key={item.title}>
                                <Link
                                    spy={true}
                                    offset={-120}
                                    smooth={true}
                                    duration={500}
                                    to={makeTitleToDOMId(item.title)}
                                    activeClass="!text-accent before:absolute before:bg-accent before:h-5 before:h-full before:left-0 before:top-0.5 before:w-0.5 font-semibold pl-4 relative text-sm"
                                    className="inline-flex py-3 uppercase cursor-pointer"
                                >
                                    {t(item.title)}
                                </Link>
                            </li>
                        ))}
                    </ol>
                </nav>
                {/* End of section scroll spy menu */}

                <div className="md:w-9/12 md:pb-10 ltr:md:pl-8 rtl:md:pr-8">
                    {content?.map((item) => (
                        <Element
                            key={item.title}
                            name={makeTitleToDOMId(item.title)}
                            className="mb-10"
                        >
                            <h2 className="mb-4 text-lg font-bold text-heading md:text-xl lg:text-2xl">
                                {t(item.title)}
                            </h2>
                            <div
                                className="leading-loose text-body-dark"
                                dangerouslySetInnerHTML={{ __html: t(item.description) }}
                            />
                        </Element>
                    ))}
                </div>
                {/* End of content */}
            </div>
            </div>
        </section>
    );
};
TermsView.authenticationRequired = true;
export default TermsView;
