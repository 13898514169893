import type { CreateContactUsInput } from "../../types";
import Button from "../../components/ui/button";
import { Form } from "../../components/ui/forms/form";
import Input from "../../components/ui/forms/input";
import TextArea from "../../components/ui/forms/text-area";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import client from "../../service/client";
import { useCustomerContactUs } from "../../service/hook/customer";
import { toast } from "react-toastify";

const contactFormSchema = yup.object().shape({
    full_name: yup.string().required("error-name-required"),
    email: yup.string().email("error-email-format").required("error-email-required"),
    subject: yup.string().required("error-subject-required"),
    message: yup.string().required("error-description-required"),
});
// const {
//     users: { contactUs },
// } = client;

const ContactForm = () => {
    const { t } = useTranslation("common");

    const {
        mutate: mutateContactUs,
        isLoading: isLoadingContactUs,
        serverError: serverErrorContactUs,
        setServerError: setServerErrorContactUs,
    } = useCustomerContactUs();

    function onSubmit(values: CreateContactUsInput) {
        console.log("values => ", values);

        //   Todo submit form
        // contactUs(values);

        const input: CreateContactUsInput = {
            ...values,
        };

        console.log("input => ", input);

        if (!isLoadingContactUs)
            mutateContactUs(input, {
                onSuccess: (successData: any) => {
                    console.log("successData => ", successData);

                    if (successData.final_result_code === "000") {
                        toast.success(successData.general_result);
                    } else {
                        toast.error(successData.general_result);
                    }
                },
                onError: (error: Error) => {
                    const {
                        response: { data },
                    }: any = error ?? {};

                    toast.error(data?.general_result);

                    if (data?.final_result_code === "001") {
                        toast.error(data?.general_result[0]);
                    }
                },
            });
    }

    return (
        <Form<CreateContactUsInput> onSubmit={onSubmit} validationSchema={contactFormSchema}>
            {({ register, formState: { errors } }) => (
                <>
                    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                        <Input
                            label={t("text-name")}
                            {...register("full_name")}
                            variant="outline"
                            error={t(errors.full_name?.message!)}
                        />
                        <Input
                            label={t("text-email")}
                            {...register("email")}
                            type="email"
                            variant="outline"
                            error={t(errors.email?.message!)}
                        />
                    </div>
                    <Input
                        label={t("text-subject")}
                        {...register("subject")}
                        variant="outline"
                        className="my-6"
                        error={t(errors.subject?.message!)}
                    />
                    <TextArea
                        label={t("text-description")}
                        {...register("message")}
                        variant="outline"
                        className="my-6"
                        error={t(errors.message?.message!)}
                    />

                    <Button loading={false} disabled={false}>
                        {t("text-submit")}
                    </Button>
                </>
            )}
        </Form>
    );
};

export default ContactForm;
