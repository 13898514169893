import React from 'react';
export const BookIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.6"
      height="19.335"
      viewBox="0 0 24.6 19.335"
      fill="currentColor"
      {...props}
    >
      <g id="Books" transform="translate(-24.7 -30.187)">
        <g
          id="Group_12308"
          data-name="Group 12308"
          transform="translate(25 32.504)"
        >
          <g
            id="Group_12306"
            data-name="Group 12306"
            transform="translate(0 0)"
          >
            <path
              id="Path_17416"
              data-name="Path 17416"
              d="M32.612,50.142h0a34.57,34.57,0,0,0-7.238.8h0l0,0V35a0,0,0,0,1,0,0h.583a0,0,0,0,0,0,0v-.357a0,0,0,0,0,0,0h-.771a.183.183,0,0,0-.183.183V51.164a.182.182,0,0,0,.183.183.181.181,0,0,0,.036,0,28.735,28.735,0,0,1,9.287-.669.006.006,0,0,0,.006,0,.005.005,0,0,0,0-.006Z"
              transform="translate(-25 -34.629)"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="0.6"
            />
          </g>
          <g
            id="Group_12307"
            data-name="Group 12307"
            transform="translate(14.922 0)"
          >
            <path
              id="Path_17417"
              data-name="Path 17417"
              d="M64.983,34.629h-.776V35H64.8V50.957c-.5-.063-1.009-.13-1.515-.2a47.178,47.178,0,0,0-6.073-.546l-.013,0-1.075.311a.048.048,0,0,0,.017.094,37.04,37.04,0,0,1,7.1.5c.576.076,1.152.152,1.724.223a.183.183,0,0,0,.205-.181V34.812A.183.183,0,0,0,64.983,34.629Z"
              transform="translate(-56.088 -34.629)"
              fill="currentColor"
              stroke="currentColor"
              strokeWidth="0.6"
            />
          </g>
        </g>
        <g
          id="Group_12309"
          data-name="Group 12309"
          transform="translate(26.919 30.542)"
        >
          <path
            id="Path_17418"
            data-name="Path 17418"
            d="M49.16,30.842a.183.183,0,0,0-.166-.182c-.314-.029-7.587-.67-9.915,1.365-2.327-2.035-9.6-1.393-9.915-1.365a.183.183,0,0,0-.166.182v15.7a.183.183,0,0,0,.195.183c.073,0,7.272-.441,9.754,2.133a.187.187,0,0,0,.062.042h0a.186.186,0,0,0,.069.013.182.182,0,0,0,.069-.013h0a.187.187,0,0,0,.062-.042c2.481-2.573,9.682-2.138,9.754-2.133a.187.187,0,0,0,.137-.049.183.183,0,0,0,.058-.134Zm-19.8,15.51V31.011c1.122-.085,7.578-.49,9.531,1.336V48.314c-2.072-1.743-6.2-1.988-8.356-1.988C30.006,46.326,29.594,46.341,29.364,46.352Zm19.429,0c-1.16-.056-6.948-.212-9.531,1.962V32.347c1.954-1.827,8.409-1.421,9.531-1.336Z"
            transform="translate(-28.998 -30.542)"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.6"
          />
        </g>
      </g>
    </svg>
  );
};
