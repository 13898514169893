import React from "react";
// import ErrorMessage from "../ui/error-message";
import StickySidebarListCategories from "./sticky-sidebar-list-categories";
// import ErrorMessage from "@/components/ui/error-message";
// import dynamic from "next/dynamic";
// import { useCategories } from "@/framework/category";

// const StickySidebarListCategories = React.lazy(() => import("./sticky-sidebar-list-categories"));
// const StaticSidebarVerticalRectangleCategories = dynamic(
//     () => import("@/components/categories/sliding-vertical-rectangle-categories")
// );
// const StickySidebarBoxedCategories = dynamic(
//     () => import("@/components/categories/sticky-sidebar-boxed-categories")
// );
// const FilterCategoryGrid = dynamic(() => import("@/components/categories/filter-category-grid"));
// const SlidingCardCategories = dynamic(
//     () => import("@/components/categories/sliding-card-category")
// );
// const MAP_CATEGORY_TO_GROUP: Record<string, any> = {
//     classic: StickySidebarListCategories,
//     // modern: StickySidebarBoxedCategories,
//     // standard: StaticSidebarVerticalRectangleCategories,
//     // minimal: FilterCategoryGrid,
//     // compact: SlidingCardCategories,
//     // default: StickySidebarListCategories,
// };
interface CategoriesProps {
    layout: string;
    variables: any;
    className?: string;
}

// export interface Attachment {
//     id: number;
//     original: string;
//     thumbnail: string;
//     __typename?: string;
//     slug?: string;
// }

const categoriesData = {
    data: [
        {
            id: "68",
            name: "Women Dress",
            slug: "women-dress",
            icon: "WomenDress",
            image: {
                id: 1,
                original: "",
                thumbnail: "",
            },
            details: null,
            language: "en",
            translated_languages: ["en"],
            parent: null,
            type_id: 5,
            created_at: "2021-03-09T06:25:14.000Z",
            updated_at: "2021-03-09T06:25:14.000Z",
            deleted_at: null,
            parent_id: null,
            type: {
                id: 5,
                name: "Clothing",
                language: "en",
                translated_languages: ["en"],
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
            },
            children: [
                {
                    id: 69,
                    name: "Printed",
                    slug: "printed",
                    icon: null,
                    image: [
                        {
                            id: 1,
                            original: "",
                            thumbnail: "",
                        },
                    ],
                    details: null,
                    language: "en",
                    translated_languages: ["en"],
                    parent: {
                        id: 68,
                        name: "Women Dress",
                        slug: "women-dress",
                        icon: "WomenDress",
                        image: [],
                        details: null,
                        language: "en",
                        translated_languages: ["en"],
                        parent: null,
                        type_id: 5,
                        created_at: "2021-03-09T06:25:14.000000Z",
                        updated_at: "2021-03-09T06:25:14.000000Z",
                        deleted_at: null,
                        parent_id: null,
                        type: null,
                        children: null,
                    },
                    type_id: 5,
                    created_at: "2021-03-09T06:26:10.000000Z",
                    updated_at: "2021-03-09T06:26:10.000000Z",
                    deleted_at: null,
                    products_count: 1,
                    parent_id: 68,
                    children: [],
                },
                {
                    id: 70,
                    name: "Floral",
                    slug: "floral",
                    icon: null,
                    image: [
                        {
                            id: 1,
                            original: "",
                            thumbnail: "",
                        },
                    ],
                    details: null,
                    language: "en",
                    translated_languages: ["en"],
                    parent: {
                        id: 68,
                        name: "Women Dress",
                        slug: "women-dress",
                        icon: "WomenDress",
                        image: [],
                        details: null,
                        language: "en",
                        translated_languages: ["en"],
                        parent: null,
                        type_id: 5,
                        created_at: "2021-03-09T06:25:14.000000Z",
                        updated_at: "2021-03-09T06:25:14.000000Z",
                        deleted_at: null,
                        parent_id: null,
                        type: null,
                        children: null,
                    },
                    type_id: 5,
                    created_at: "2021-03-09T06:26:52.000000Z",
                    updated_at: "2021-03-09T06:26:52.000000Z",
                    deleted_at: null,
                    products_count: 1,
                    parent_id: 68,
                    children: [],
                },
                {
                    id: 71,
                    name: "Single Color",
                    slug: "single-color",
                    icon: null,
                    image: [
                        {
                            id: 1,
                            original: "",
                            thumbnail: "",
                        },
                    ],
                    details: null,
                    language: "en",
                    translated_languages: ["en"],
                    parent: {
                        id: 68,
                        name: "Women Dress",
                        slug: "women-dress",
                        icon: "WomenDress",
                        image: [],
                        details: null,
                        language: "en",
                        translated_languages: ["en"],
                        parent: null,
                        type_id: 5,
                        created_at: "2021-03-09T06:25:14.000000Z",
                        updated_at: "2021-03-09T06:25:14.000000Z",
                        deleted_at: null,
                        parent_id: null,
                        type: null,
                        children: null,
                    },
                    type_id: 5,
                    created_at: "2021-03-09T06:27:12.000000Z",
                    updated_at: "2021-03-09T06:27:12.000000Z",
                    deleted_at: null,
                    products_count: 2,
                    parent_id: 68,
                    children: [],
                },
            ],
            description: "",
        },
        {
            id: "72",
            name: "Outer Wear",
            slug: "outer-wear",
            icon: "OuterWear",
            image: {
                id: 1,
                original: "",
                thumbnail: "",
            },
            details: null,
            language: "en",
            translated_languages: ["en"],
            parent: null,
            type_id: 5,
            created_at: "2021-03-09T08:55:55.000Z",
            updated_at: "2021-03-09T08:55:55.000Z",
            deleted_at: null,
            parent_id: null,
            type: {
                id: 5,
                name: "Clothing",
                language: "en",
                translated_languages: ["en"],
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
            },
            children: [
                {
                    id: 73,
                    name: "Hoodie",
                    slug: "hoodie",
                    icon: null,
                    image: [
                        {
                            id: 1,
                            original: "",
                            thumbnail: "",
                        },
                    ],
                    details: null,
                    language: "en",
                    translated_languages: ["en"],
                    parent: {
                        id: 72,
                        name: "Outer Wear",
                        slug: "outer-wear",
                        icon: "OuterWear",
                        image: [],
                        details: null,
                        language: "en",
                        translated_languages: ["en"],
                        parent: null,
                        type_id: 5,
                        created_at: "2021-03-09T08:55:55.000000Z",
                        updated_at: "2021-03-09T08:55:55.000000Z",
                        deleted_at: null,
                        parent_id: null,
                        type: null,
                        children: null,
                    },
                    type_id: 5,
                    created_at: "2021-03-09T08:56:39.000000Z",
                    updated_at: "2021-03-09T08:56:39.000000Z",
                    deleted_at: null,
                    products_count: 9,
                    parent_id: 72,
                    children: [],
                },
                {
                    id: 74,
                    name: "Jacket",
                    slug: "jacket",
                    icon: null,
                    image: [
                        {
                            id: 1,
                            original: "",
                            thumbnail: "",
                        },
                    ],
                    details: null,
                    language: "en",
                    translated_languages: ["en"],
                    parent: {
                        id: 72,
                        name: "Outer Wear",
                        slug: "outer-wear",
                        icon: "OuterWear",
                        image: [],
                        details: null,
                        language: "en",
                        translated_languages: ["en"],
                        parent: null,
                        type_id: 5,
                        created_at: "2021-03-09T08:55:55.000000Z",
                        updated_at: "2021-03-09T08:55:55.000000Z",
                        deleted_at: null,
                        parent_id: null,
                        type: null,
                        children: null,
                    },
                    type_id: 5,
                    created_at: "2021-03-09T08:56:55.000000Z",
                    updated_at: "2021-03-09T08:56:55.000000Z",
                    deleted_at: null,
                    products_count: 5,
                    parent_id: 72,
                    children: [],
                },
                {
                    id: 75,
                    name: "Blazer",
                    slug: "blazer",
                    icon: null,
                    image: [
                        {
                            id: 1,
                            original: "",
                            thumbnail: "",
                        },
                    ],
                    details: null,
                    language: "en",
                    translated_languages: ["en"],
                    parent: {
                        id: 72,
                        name: "Outer Wear",
                        slug: "outer-wear",
                        icon: "OuterWear",
                        image: [],
                        details: null,
                        language: "en",
                        translated_languages: ["en"],
                        parent: null,
                        type_id: 5,
                        created_at: "2021-03-09T08:55:55.000000Z",
                        updated_at: "2021-03-09T08:55:55.000000Z",
                        deleted_at: null,
                        parent_id: null,
                        type: null,
                        children: null,
                    },
                    type_id: 5,
                    created_at: "2021-03-09T08:57:20.000000Z",
                    updated_at: "2021-03-09T08:57:20.000000Z",
                    deleted_at: null,
                    products_count: 7,
                    parent_id: 72,
                    children: [],
                },
                {
                    id: 76,
                    name: "Waist Coat",
                    slug: "waist-coat",
                    icon: null,
                    image: [
                        {
                            id: 1,
                            original: "",
                            thumbnail: "",
                        },
                    ],
                    details: null,
                    language: "en",
                    translated_languages: ["en"],
                    parent: {
                        id: 72,
                        name: "Outer Wear",
                        slug: "outer-wear",
                        icon: "OuterWear",
                        image: [],
                        details: null,
                        language: "en",
                        translated_languages: ["en"],
                        parent: null,
                        type_id: 5,
                        created_at: "2021-03-09T08:55:55.000000Z",
                        updated_at: "2021-03-09T08:55:55.000000Z",
                        deleted_at: null,
                        parent_id: null,
                        type: null,
                        children: null,
                    },
                    type_id: 5,
                    created_at: "2021-03-09T08:57:40.000000Z",
                    updated_at: "2021-03-09T08:57:40.000000Z",
                    deleted_at: null,
                    products_count: 12,
                    parent_id: 72,
                    children: [],
                },
            ],
            description: "",
        },
        {
            id: "77",
            name: "Pants",
            slug: "pants",
            icon: "Pants",
            image: {
                id: 1,
                original: "",
                thumbnail: "",
            },
            details: null,
            language: "en",
            translated_languages: ["en"],
            parent: null,
            type_id: 5,
            created_at: "2021-03-09T08:58:22.000Z",
            updated_at: "2021-03-09T08:58:22.000Z",
            deleted_at: null,
            parent_id: null,
            type: {
                id: 5,
                name: "Clothing",
                language: "en",
                translated_languages: ["en"],
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
            },
            children: [
                {
                    id: 78,
                    name: "Jeans",
                    slug: "jeans",
                    icon: null,
                    image: [
                        {
                            id: 1,
                            original: "",
                            thumbnail: "",
                        },
                    ],
                    details: null,
                    language: "en",
                    translated_languages: ["en"],
                    parent: {
                        id: 77,
                        name: "Pants",
                        slug: "pants",
                        icon: "Pants",
                        image: [],
                        details: null,
                        language: "en",
                        translated_languages: ["en"],
                        parent: null,
                        type_id: 5,
                        created_at: "2021-03-09T08:58:22.000000Z",
                        updated_at: "2021-03-09T08:58:22.000000Z",
                        deleted_at: null,
                        parent_id: null,
                        type: null,
                        children: null,
                    },
                    type_id: 5,
                    created_at: "2021-03-09T08:58:43.000000Z",
                    updated_at: "2021-03-09T08:58:43.000000Z",
                    deleted_at: null,
                    products_count: 4,
                    parent_id: 77,
                    children: [],
                },
                {
                    id: 79,
                    name: "Chinos",
                    slug: "chinos",
                    icon: null,
                    image: [
                        {
                            id: 1,
                            original: "",
                            thumbnail: "",
                        },
                    ],
                    details: null,
                    language: "en",
                    translated_languages: ["en"],
                    parent: {
                        id: 77,
                        name: "Pants",
                        slug: "pants",
                        icon: "Pants",
                        image: [],
                        details: null,
                        language: "en",
                        translated_languages: ["en"],
                        parent: null,
                        type_id: 5,
                        created_at: "2021-03-09T08:58:22.000000Z",
                        updated_at: "2021-03-09T08:58:22.000000Z",
                        deleted_at: null,
                        parent_id: null,
                        type: null,
                        children: null,
                    },
                    type_id: 5,
                    created_at: "2021-03-09T08:58:57.000000Z",
                    updated_at: "2021-03-09T08:58:57.000000Z",
                    deleted_at: null,
                    products_count: 7,
                    parent_id: 77,
                    children: [],
                },
                {
                    id: 80,
                    name: "Sports",
                    slug: "sports",
                    icon: null,
                    image: [
                        {
                            id: 1,
                            original: "",
                            thumbnail: "",
                        },
                    ],
                    details: null,
                    language: "en",
                    translated_languages: ["en"],
                    parent: {
                        id: 77,
                        name: "Pants",
                        slug: "pants",
                        icon: "Pants",
                        image: [],
                        details: null,
                        language: "en",
                        translated_languages: ["en"],
                        parent: null,
                        type_id: 5,
                        created_at: "2021-03-09T08:58:22.000000Z",
                        updated_at: "2021-03-09T08:58:22.000000Z",
                        deleted_at: null,
                        parent_id: null,
                        type: null,
                        children: null,
                    },
                    type_id: 5,
                    created_at: "2021-03-09T08:59:13.000000Z",
                    updated_at: "2021-03-09T08:59:13.000000Z",
                    deleted_at: null,
                    products_count: 8,
                    parent_id: 77,
                    children: [],
                },
            ],
            description: "",
        },
        {
            id: "81",
            name: "Tops",
            slug: "tops",
            icon: "Tops",
            image: {
                id: 1,
                original: "",
                thumbnail: "",
            },
            details: null,
            language: "en",
            translated_languages: ["en"],
            parent: null,
            type_id: 5,
            created_at: "2021-03-09T08:59:55.000Z",
            updated_at: "2021-03-09T08:59:55.000Z",
            deleted_at: null,
            parent_id: null,
            type: {
                id: 5,
                name: "Clothing",
                language: "en",
                translated_languages: ["en"],
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
            },
            children: [],
            description: "",
        },
        {
            id: "82",
            name: "Skirts",
            slug: "skirts",
            icon: "Skirts",
            image: {
                id: 1,
                original: "",
                thumbnail: "",
            },
            details: null,
            language: "en",
            translated_languages: ["en"],
            parent: null,
            type_id: 5,
            created_at: "2021-03-09T09:01:43.000Z",
            updated_at: "2021-03-25T13:01:38.000Z",
            deleted_at: null,
            parent_id: null,
            type: {
                id: 5,
                name: "Clothing",
                language: "en",
                translated_languages: ["en"],
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
            },
            children: [],
            description: "",
        },
        {
            id: "83",
            name: "Shirts",
            slug: "shirts",
            icon: "Shirts",
            image: {
                id: 1,
                original: "",
                thumbnail: "",
            },
            details: null,
            language: "en",
            translated_languages: ["en"],
            parent: null,
            type_id: 5,
            created_at: "2021-03-09T09:02:05.000Z",
            updated_at: "2021-03-09T09:02:05.000Z",
            deleted_at: null,
            parent_id: null,
            type: {
                id: 5,
                name: "Clothing",
                language: "en",
                translated_languages: ["en"],
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
            },
            children: [],
            description: "",
        },
    ],
    total: 6,
    current_page: 1,
    count: 6,
    last_page: 1,
    firstItem: 0,
    lastItem: 5,
    per_page: "1000",
    first_page_url:
        "http://localhost:5000/api/categories?search=type.slug:clothing&limit=1000&parent=null&page=1",
    last_page_url:
        "http://localhost:5000/api/categories?search=type.slug:clothing&limit=1000&parent=null&page=1",
    next_page_url: null,
    prev_page_url: null,
};
const Categories = ({ layout, className, variables }: CategoriesProps) => {
    const categoriesDt = [
        {
            id: "1",
            name: "Fruits & Vegetables",
            slug: "fruits-vegetables",
            icon: "FruitsVegetable",
            description: "",
            image: {
                id: 1,
                original: "",
                thumbnail: "",
                __typename: "",
                slug: "",
            },
            parent_id: 0,
        },
        {
            id: "2",
            name: "Fruits",
            slug: "fruits",
            description: "",
            image: {
                id: 1,
                original: "",
                thumbnail: "",
                __typename: "",
                slug: "",
            },
            parent_id: 1,
        },
    ];

    // const { categories, isLoading, error } = useCategories(variables);

    // if (error) return <ErrorMessage message={error.message} />;
    // const Component = MAP_CATEGORY_TO_GROUP[layout];
    return (
        <>
            <StickySidebarListCategories
                notFound={!Boolean(categoriesData.data.length)}
                // notFound={false}
                categories={categoriesData.data}
                loading={false}
                className={className}
                // variables={variables}
            />
        </>
        // <Component
        //     notFound={!Boolean(categoriesData.data.length)}
        //     categories={categoriesData.data}
        //     loading={false}
        //     className={className}
        //     variables={variables}
        // />
    );
};

export default Categories;
