import { create, StateCreator } from 'zustand';
import { persist, PersistOptions, createJSONStorage } from 'zustand/middleware';

export type AuthState = {
  accessToken: string;
  setAccessToken: (token: string) => void;
  email: string;
  setEmail: (token: string) => void;
  isAuthorized: boolean;
  setAuthorized: (isAuthorized: boolean) => void;
};

type AuthPersist = (
  config: StateCreator<AuthState>,
  options: PersistOptions<AuthState>
) => StateCreator<AuthState>;

export const authStore = create<AuthState>(
  (persist as unknown as AuthPersist)(
    (set) => ({
      accessToken: '51245cffb61b6370083e1b71fb2080f7c55aa225',
      setAccessToken: (accessToken: string) => set({ accessToken }),
      email: '',
      setEmail: (email: string) => set({ email }),
      isAuthorized: false,
      setAuthorized: (isAuthorized: boolean) => set({ isAuthorized }),
    }),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default authStore;
