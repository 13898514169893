import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import client from "../client";

export function useProductCategoryShowName() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.customer.productCategoryShowName, {
        onSuccess: (data) => {
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                // toast.error(`${data?.email}`);
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useProductSearchGridShow() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.customer.productSearchGridShow, {
        onSuccess: (data) => {
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                // toast.error(`${data?.email}`);
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useMarketPlaceShow() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.customer.marketPlaceShow, {
        onSuccess: (data) => {
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                // toast.error(`${data?.email}`);
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

export function useCustomerContactUs() {
    let [serverError, setServerError] = useState<string | null>(null);


    const { mutate, isLoading } = useMutation(client.customer.contactUs, {
        onSuccess: (data) => {
        },
        onError: (error: Error) => {
            const {
                response: { data },
            }: any = error ?? {};

            if (data.non_field_errors) {
                setServerError(data.non_field_errors);
            } else if (data) {
                // toast.error(`${data?.email}`);
                setServerError(error.message);
            }
        },
    });

    return { mutate, isLoading, serverError, setServerError };
}

