import React from "react";
import { Routes } from "../../../config/routes";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import Link from '@/components/ui/link';
// import { Routes } from '@/config/routes';

const headerLinks = [
    { href: Routes.home, icon: null, label: "nav-menu-shops" },
    { href: "#", icon: null, label: "nav-menu-offer" },
    { href: Routes.faq, label: "nav-menu-faq" },
    { href: Routes.gdpr, label: "nav-menu-gdpr" },
    { href: Routes.terms, label: "nav-menu-terms" },
    { href: Routes.contact, label: "nav-menu-contact" },
];

const StaticMenu = () => {
    const { t } = useTranslation("common");

    return (
        <>
            {headerLinks.map(({ href, label, icon }) => (
                <li key={`${href}${label}`}>
                    <Link
                        to={href}
                        className="flex items-center font-normal text-heading no-underline transition duration-200 hover:text-accent focus:text-accent"
                    >
                        {icon && <span className="ltr:mr-2 rtl:ml-2">{icon}</span>}
                        {t(label)}
                    </Link>
                </li>
            ))}
        </>
    );
};

export default StaticMenu;
