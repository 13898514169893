import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearch } from "./search.context";
import SearchBox from "./search-box";
import {
    useProductCategoryShowName,
    useProductSearchGridShow,
} from "../../../service/hook/customer";
import {
    ProductCategoryShowNameReq,
    ProductCategoryShowNameResponse,
    ProductSearchGridShowItem,
    ProductSearchGridShowReq,
    ProductSearchGridShowResponse,
} from "../../../types";
import { toast } from "react-toastify";
import { ProductCategoryShowNameItem } from "../../../types/index";
import pubStore from "../../../store/pubStore";
interface Props {
    label: string;
    variant?: "minimal" | "normal" | "with-shadow" | "flat";
    [key: string]: unknown;
}

const Search: React.FC<Props> = ({ label, variant, ...props }) => {
    const { t } = useTranslation();
    // const router = useRouter();
    const { setSearchItems } = pubStore((state) => state);
    const { searchTerm, updateSearchTerm } = useSearch();

    const [isSearchBoxFocus, setIsSearchBoxFocus] = useState<boolean>(false);
    const [isShowSuggestion, setIsShowSuggestion] = useState<boolean>(false);
    const [suggestionItems, setSuggestionItems] = useState<ProductCategoryShowNameItem[]>([]);
    const [suggestionItem, setSuggestionItem] = useState<ProductCategoryShowNameItem | undefined>(
        undefined
    );
    // const [searchItems, setSearchItems] = useState<ProductSearchGridShowItem[]>([]);

    const {
        mutate: mutateProductCategoryShowName,
        isLoading: isLoadingProductCategoryShowName,
        serverError: serverErrorProductCategoryShowName,
        setServerError: setServerErrorProductCategoryShowName,
    } = useProductCategoryShowName();

    const {
        mutate: mutateProductSearchGridShow,
        isLoading: isLoadingProductSearchGridShow,
        serverError: serverErrorProductSearchGridShow,
        setServerError: setServerErrorProductSearchGridShow,
    } = useProductSearchGridShow();

    useEffect(() => {
        if (searchTerm) {
            const timer = setTimeout(() => {
                if (searchTerm.length >= 2) {
                    if (isSearchBoxFocus) onSearch(searchTerm);
                }
            }, 600);
            return () => clearTimeout(timer);
        }
    }, [searchTerm]);

    const handleOnChange = (e: any) => {
        const { value } = e.target;
        updateSearchTerm(value);
    };

    const onSearch = (e: any) => {
        // e.preventDefault();
        if (!searchTerm) return;

        const input: ProductCategoryShowNameReq = {
            category_name: searchTerm,
        };

        mutateProductCategoryShowName(input, {
            onSuccess: (successData: ProductCategoryShowNameResponse) => {
                if (successData.final_result_code === "000") {
                    setSuggestionItems(successData.captured_data);
                    setIsShowSuggestion(true);
                } else {
                    toast.error(successData.general_result);
                }
            },
            onError: (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);

                if (data?.final_result_code === "001") {
                    toast.error(data?.general_result[0]);
                }
            },
        });
    };

    const productSearch = (item: ProductCategoryShowNameItem) => {
        if (!searchTerm) return;

        const input: ProductSearchGridShowReq = {
            page: 1,
            size: 10,
            marketplaceId: "GBR",
            level: `${item.level ?? ""}`,
            category: `${item.category_id ?? ""}`,
        };

        mutateProductSearchGridShow(input, {
            onSuccess: (successData: ProductSearchGridShowResponse) => {
                if (successData.final_result_code === "000") {
                    setSearchItems(successData.captured_data);
                    setIsShowSuggestion(false);
                } else {
                    toast.error(successData.general_result);
                }
            },
            onError: (error: Error) => {
                const {
                    response: { data },
                }: any = error ?? {};

                toast.error(data?.general_result);

                if (data?.final_result_code === "001") {
                    toast.error(data?.general_result[0]);
                }
            },
        });
    };

    function clearSearch() {
        updateSearchTerm("");
        setSuggestionItems([]);
        setIsShowSuggestion(false);
        // const { pathname, query } = router;
        // const { text, ...rest } = query;
        // if (text) {
        //   router.push(
        //     {
        //       pathname,
        //       query: { ...rest },
        //     },
        //     undefined,
        //     {
        //       scroll: false,
        //     }
        //   );
        // }
    }

    const onSuggestionListItemClick = (item: ProductCategoryShowNameItem) => {
        setIsShowSuggestion(false);
        productSearch(item);
        updateSearchTerm(item.category_name);
        setSuggestionItem(item);
    };

    const searchBoxFocus = () => {
        setIsSearchBoxFocus(true);
        setIsShowSuggestion(true);
        setSuggestionItem(undefined);
    };

    return (
        <SearchBox
            label={label}
            onSubmit={onSearch}
            onClearSearch={clearSearch}
            onChange={handleOnChange}
            onFocus={searchBoxFocus}
            onBlur={() => setIsSearchBoxFocus(false)}
            defaultValue={""}
            value={searchTerm}
            placeholder={t("common:text-search-placeholder")}
            variant={variant}
            suggestionList={suggestionItems}
            onSuggestionListItemClick={(item: ProductCategoryShowNameItem) =>
                onSuggestionListItemClick(item)
            }
            isShowSuggestion={isShowSuggestion}
            {...props}
        />
    );
};

export default Search;
