import React from "react";
import ErrorMessage from "../ui/error-message";
import PromotionSlider from "./promotion-slider";
// import PromotionSlider from "@/components/promotions/promotion-slider";
// import ErrorMessage from "@/components/ui/error-message";
// import { useType } from "@/framework/type";

export default function PromotionSliders() {
    // const { type, error } = useType(variables.type);
    if (false) return <ErrorMessage message={"error.message"} />;
    // if (!type?.promotional_sliders) return null;
    const sliders = [
        {
            id: "902",
            original: "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
            thumbnail:
                "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
        },
        {
            id: "903",
            original: "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
            thumbnail:
                "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
        },
        {
            id: "904",
            original: "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
            thumbnail:
                "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
        },
        {
            id: "905",
            original: "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
            thumbnail:
                "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
        },
    ];
    return <PromotionSlider sliders={sliders} />;
}
