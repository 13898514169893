import { create } from "zustand";
import { persist } from "zustand/middleware";
import { MarketPlaceShowItem } from "../types";

export interface AuthorizationUser {
    firstName?: string;
    lastName?: string;
    email: string;
    isConfirmEmail: boolean;
}

export interface UserState {
    user: AuthorizationUser | null;
    setUser: (user: AuthorizationUser) => void;
    marketPlaceItem: MarketPlaceShowItem | undefined;
};

// const userStore = create<UserState>((set?: any) => ({
//     user: null,
//     setUser: (user: any) => set({ user }),
// }));

const userStore = create<UserState>()(
    persist(
        (set) => ({
            user: null,
            setUser: (prvUser: AuthorizationUser) => set((state) => ({ user: { ...state.user, ...prvUser } })),
            marketPlaceItem: undefined,
        }),
        {
            name: 'user-storage',
        }
    )

)

export default userStore;
